<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>
                  <button id="carga_pdf"  class="btn btn-md btn-warning w-20" style="padding: 10px;position: absolute;right: 30px;">Cargar E-check's otorgados</button>
                  <br><br>
                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>


                <div class="row mb-3">

                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>

                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>

                  <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Cuenta Emisora</label>
                        <select class="form-control mb-2 mr-sm-2" id="cuenta">
                            <option value="00170466420100010728">América</option>
                            <option value="00170328630103154167">Catering</option>
                        </select>
                    </div>


                 



                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Cuit Emisor</label>
                        <select class="form-control mb-2 mr-sm-2" id="emisor">
                            <option value="30708014652">América</option>
                            <option value="30716562286">Catering</option>
                        </select>
                    </div>

                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">EMAIL GENERADOR</label>
                        <input type="email" class="form-control mb-2 mr-sm-2" id='email' value="ADOSSANTOS@FOODSERVICE.COM.AR"/>
                    </div>

                     <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">EMPRESA</label>
                        <select class="form-control mb-2 mr-sm-2" id='empresa'>
                            <option value="0">Todas</option>
                            <option value="1">America</option>
                            <option value="2">Catering</option>
                        </select>
                    </div>

                  
                    
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="obtenerPersonal()" style="padding: 10px;">Consultar E-Check's</button>
                    </div>
                </div>
          


                        </div>  
                      </div>
                    </div>
                  </div>    

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title" id="mestext"></h6>
                           <div id="alerta" class="alert alert-danger" role="alert">Para poder visualizar un reporte debera un rango de fechas</div>




                            <div id="espera" class="alert alert-warning" role="alert">Espere un instante por favor!. Estamos cargando todos los datos solicitados. ESTA OPERACIÓN PUEDE TARDAR VARIOS SEGUNDOS</div>
                            <div id="progreso"></div>


                            <table width="100%" style="font-size: 26px; border: 1px solid rgb(215, 215, 215);" id="calculo">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div id="fecha" style="padding:10px;background-color:#f7f3f3;">
                                                E-Check's: <span id="semana_numero">0</span><br>
                                                 <button  class="btn btn-md btn-danger w-100" id="descargar" style="padding: 10px;">APROBAR, GUARDAR Y DESCARGAR ARCHIVO BANCARIO</button>
                                            </div>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                          <br><br>
                          <div id="generacion">Fecha de Generación: <input type="date" id="fechaInput" placeholder="Seleccione una fecha" style="margin-right: 10px;"></div>

                          <div class="table-responsive">

                                <table id="consumos" class="table display" ></table>

                          </div>
                  
                      

                        </div>
                      </div>
                    </div>
                  </div>


             

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

$.fn.dataTable.ext.errMode = 'none';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
const userData = JSON.parse(sessionStorage.getItem('userData'));

export default {
    name: 'Personal',
    
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Cheques electronicos V.13.0.0',
            descripcion: 'En esta seccion visualizara los E-Checks',
            triggers: {load:true},
            semanas: [],
            
        }
    },
    methods: {
       

        obtenerPersonal(){
            localStorage.setItem("total",0);
            localStorage.removeItem("busqueda")
            var isDownloading = false;
            let json=false;
            $("#espera").slideDown();
            $('#consumos_wrapper').empty();
            $('#consumos_wrapper').html('<table id="consumos" class="table"></table>');


            $('#consolidado_wrapper').empty();
            $('#consolidado_wrapper').html('<table id="consolidado" class="table"></table>');
            $("#calculo").slideUp();


      
           var fechas = [];

           var desde = $("#desde").val();
           var hasta = $("#hasta").val();
           var operacion = $("#operacion").val();

           var inicio = desde;
           var fin = hasta;
           var dateOffset = (24*60*60*1000) * 1; //1 days

           var fechaInicio  = new Date(inicio);
           var fechaFin     = new Date(fin);

           fechaInicio.setTime(fechaInicio.getTime());
           fechaFin.setTime(fechaFin.getTime());

            while(fechaFin.getTime() >= fechaInicio.getTime()){
            fechaInicio.setDate(fechaInicio.getDate() + 1);

           
            }



            var array = [];
             $.ajax({
                  url : 'https://aplicativos.firebaseio.com/echeck_pagados.json',
                  type:'GET',
                 success : function(json) {
                    
                    $.each(json,function(clave,valor){
                        array.push(parseInt(clave))
                    })

                 }

             });

          
                    


                        
                $("#loading").hide();
                $("#alerta").slideUp();
                setTimeout(function(){
                    console.log("ok")
                    $('#consumos').show()
                },1000)

                     

            $("#semana_numero").html(desde+' hasta '+hasta)


          
             const formatearfecha=(fecha)=>{
                
                
                    var fechaEntrada = new Date(fecha);
                    // Obtener componentes de la fecha
                    var año = fechaEntrada.getFullYear();
                    var mes = (fechaEntrada.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 al mes porque los meses van de 0 a 11
                    var dia = fechaEntrada.getDate().toString().padStart(2, '0');
                    // Formatear la fecha
                    var fechaFormateada = año + mes + dia;
                    return fechaFormateada;


             }


             function monto(numero) {
                // Redondear el número a dos decimales y convertirlo a cadena
                var numeroCadena = Math.round(numero * 100).toString();

                // Asegurarse de que la cadena tenga al menos 12 caracteres
                numeroCadena = numeroCadena.padStart(12, '0');

                return numeroCadena;
            }


            function montoTotal(numero) {
                // Redondear el número a dos decimales y convertirlo a cadena
                var numeroCadena = Math.round(numero * 100).toString();

                // Asegurarse de que la cadena tenga al menos 12 caracteres
                numeroCadena = numeroCadena.padStart(15, '0');

                return numeroCadena;
            }


            // Función para generar una cadena alfanumérica aleatoria
            function generarCadenaAlfanumerica(longitud) {
                var resultado = '';
                var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var longitudCaracteres = caracteres.length;
                for ( var i = 0; i < longitud; i++ ) {
                    resultado += caracteres.charAt(Math.floor(Math.random() * longitudCaracteres));
                }
                return resultado;
            }



             // Función para generar una cadena alfanumérica aleatoria
            function getDatos(desde,hasta) {
                
                 $.ajax({
                  url : 'https://aplicativos.firebaseio.com/echeck_registrados.json?orderBy=%22FECHA%22&startAt=%22'+desde+'%22&endAt=%22'+hasta+'%22&print=pretty',
                  type:'GET',
                    success : function(json) {
                        localStorage.setItem("capturado",JSON.stringify(json))
                    }
                 });

               
            }
                

            getDatos(desde,hasta)



                $.ajax({
                  url : 'https://auth.foodservice.com.ar/?type=echeck&desde='+desde+'&hasta='+hasta+'&access_token=1234567',
                  type:'GET',
                 success : function(json) {

                        var json1 = JSON.parse(json);

                        var json2 = JSON.parse(localStorage.getItem("capturado"));


                        // Suponiendo que tus objetos se llaman json1 y json2
                        const numeroExtJson1 = Object.values(json1).map(obj => obj.NUMEROEXT.trim());
                        const numeroExtJson2 = Object.values(json2).map(obj => obj.NUMEROEXT.trim());

                        // Encontrar coincidencias
                        const repetidos = numeroExtJson1.filter(value => numeroExtJson2.includes(value));

                        var repe = "";
                        for (var i = 0; i < repetidos.length; i++) {
                            repe +=repetidos[i]+"<br>";
                        }

                        if (repetidos.length>0) {
                            Swal.fire(
                              "En la busqueda iniciada ya existen cheques generados y descargados",
                              repe,
                              "danger"
                            );
                        }

                        $(".table-responsive").css("visibility","visible");
                        $("#espera").slideUp();
                        $("#calculo").slideDown();
                         $("#progreso").html("");
                        setTimeout(function(){
                            var cons = parseInt($("#consolidado_numero").html());
                            var pedi = parseInt($("#pedidos_numero").html());
                            $("#diferencia_numero").html(cons-pedi);

                        },1000)



                        var columna = []; 
                       
                        columna.push({"title":"#"})
                        columna.push({"title":"FECHA"})
                        columna.push({"title":"NUMERO"})
                        columna.push({"title":"PROVEEDOR"})
                        columna.push({"title":"STATUS"})
                        columna.push({"title":"NOMBRE_VALIDADO"})
                        columna.push({"title":"TIPO_DOC"})
                        columna.push({"title":"CUIT"})
                        columna.push({"title":"CODPAG"})
                        columna.push({"title":"IMPORTE"})
                        
                        columna.push({"title":"NROTRANS"})
                        columna.push({"title":"NUMEROEXT"})
                        columna.push({"title":"SECUENCIA"})
                        columna.push({"title":"FECHAVTO"})
                        


                        
               $("#generacion").show();


                        var tabla = $('#consumos').DataTable({
                                
                                "order": [[11, "asc"]],

                                    columns: columna,
                                    select: {
                                       style: 'multi'
                                       },
                                  // Botones de DataTable

                                    dom: 'Bfrtip',
                                    buttons: [

                
   


            {
                extend: 'print',
                text: 'IMPRIMIR PRUEBA',
                exportOptions: {
                columns: [1, 2, 3, 7, 9, 10, 11, 13] // Indicando las columnas que quieres exportar
            },
                

            

            customize: function (win) {


                     var selectedRows = [];
                    var totalImporte = 0;
                    var newIndex = 0;

                    // Obtener todas las filas del DataTable, incluidas las de otras páginas
                    tabla.rows({ order: 'applied' }).every(function() {
                        // Obtener los datos de la fila
                        var data = this.data();

                        // Si necesitas trabajar con el nodo DOM de la fila
                        var rowNode = this.node();

                        // Aquí puedes agregar tu lógica para trabajar con las filas
                        var $checkbox = $(rowNode).find('input.row-check').is(':checked');

                        if ($checkbox) {
                            // Almacenar la fila seleccionada en el objeto, utilizando el nuevo índice como clave
                            selectedRows.push(newIndex);
                             
                            // Sumar el valor de la columna 6 (que corresponde a valor.IMPORTE)
                            var importeString = data[9].replace(/[^\d,.-]/g, ''); // Eliminar cualquier carácter no numérico excepto punto, coma y guion

                            importeString = importeString.replace(/\./g, '').replace(/,/g, '.'); // Eliminar los puntos y reemplazar las comas por puntos
                            var importeValue = parseFloat(importeString);

                            totalImporte += importeValue;
                        }

                        // Incrementar el índice secuencialmente
                        newIndex++;
                    });

                    // selectedRows ahora contiene las filas seleccionadas con los nuevos índices secuenciales
                    console.log(selectedRows);
                    

                    localStorage.setItem("total",dollarUS.format(totalImporte))



                        //console.log(selectedRows)

                        // Ocultar todas las filas no seleccionadas, excepto el encabezado
                        $(win.document.body).find('table').addClass('compact').css('font-size', 'inherit');
                        $(win.document.body).find('table tr').each(function(index) {
                            
                            if (index === 0) {
                                return; // No ocultar el encabezado
                            }
                            if (!selectedRows.includes(index - 1)) { // Restar 1 porque el encabezado está incluido
                                $(this).hide();
                            }
                        });

                        // Fecha y hora de impresión
                        var fecha = new Date();
                        var fechaStr = fecha.toLocaleDateString() + ' ' + fecha.toLocaleTimeString();

                        // Encabezado personalizado
                        $(win.document.body)
                            .prepend('<img src="https://catering.foodservice.com.ar/images/sistema/logo.jpg" width="300px"><br><h3>Fecha de Generación: ' + $("#fechaInput").val() + '</h3>');
                        var archivo = $("#fechaInput").val().split("-");
                        $(win.document.body)
                            .append('<div style="width:100%;"><b>Total en pesos ($) : </b><span id="suma" style="font-size:23px;">' + localStorage.getItem("total") + '</span><b><br><br>Usuario generador: </b>' + userData.leg_apellido + ' ' + userData.leg_nombre + '<span style="font-size:25px;"><b><br>Nombre de archivo: </b>' + archivo[0] + archivo[1] + archivo[2] + '.EEM</span></div>');

                        // Apartado de Firma y Aclaración
                        $(win.document.body)
                            .append('<table style="margin-top: 50px; text-align:center;width:800px;" align="center"><td><div>' +
                                    '<p>--------------------------------</p>' +
                                    '<p>Pago a proveedores</p>' +
                                    '</div></td><td><div>' +
                                    '<p>--------------------------------</p>' +
                                    '<p>Jefe de finanzas</p>' +
                                    '</div></td><td><div>' +
                                    '<p>--------------------------------</p>' +
                                    '<p>Gerente de finanzas</p>' +
                                    '</div></td></table>');

                        // Otras personalizaciones de estilo si son necesarias
                        $(win.document.body).find('h3').css('text-align', 'center');

                                    




                                    }
                                }
                                // Puedes agregar más botones aquí si lo necesitas
                ]
    
                        });





                        
                                let dollarUS = Intl.NumberFormat("es-AR", {
                                    style: "currency",
                                    currency: "ARS",
                                });

                                var suma = 0;

                                $.each(JSON.parse(json),function(clave,valor){



                                   
                                        var fechas = [];
                                       
                                        var validado = valor.NOMBRE_VALIDADO.replace('SOCIEDAD DE RESPONSABILIDAD LIMITADA','S.R.L');
                                        var validado_b = validado.replace('SOCIEDAD ANONIMA','S.A');
                                        
                                        
                                        if (array.includes(parseInt(valor.NUMEROEXT))==false) {
                                            
                                            if ($("#empresa option:selected").val()==0) {
                                            $("title").html("Emisión masiva echeqs por Food Service S.A");
                                            fechas.push( '<input checked="true" type="checkbox" class="row-check" check="true" value="' + valor.NUMEROEXT + '" >',valor.FECHA.replace(" 00:00:00",""),valor.NUMERO,valor.PROVEEDOR,valor.STATUS,validado_b,'CUIT',valor.CUIT,valor.CODPAG,dollarUS.format(valor.IMPORTE),valor.NROTRANS,valor.NUMEROEXT,valor.SECUENCIA,valor.FECHAVTO.replace(" 00:00:00",""))
                                                 tabla.row.add(fechas).draw();
                                                 suma = suma+valor.IMPORTE;

                                            }

                                           
                                            if ($("#empresa option:selected").val()==1) {
                                                  $("title").html("Emisión masiva echeqs por Food Service America S.A");
                                                 $("#emp_sel").html("America")
                                                 if (valor.EMPRESA=="America") {
                                                    fechas.push( '<input checked="true" type="checkbox" class="row-check" check="true"  value="' + valor.NUMEROEXT + '" >',valor.FECHA.replace(" 00:00:00",""),valor.NUMERO,valor.PROVEEDOR,valor.STATUS,validado_b,'CUIT',valor.CUIT,valor.CODPAG,dollarUS.format(valor.IMPORTE),valor.NROTRANS,valor.NUMEROEXT,valor.SECUENCIA,valor.FECHAVTO.replace(" 00:00:00",""))
                                                     tabla.row.add(fechas).draw();
                                                     suma = suma+valor.IMPORTE;
                                                }

                                            }

                                            if ($("#empresa option:selected").val()==2) {
                                                $("title").html("Emisión masiva echeqs por Food Service Catering S.A");
                                                $("#emp_sel").html("Catering")
                                                 if (valor.EMPRESA=="Catering") {
                                                    fechas.push( '<input checked="true" type="checkbox" class="row-check" check="true"  value="' + valor.NUMEROEXT + '" >',valor.FECHA.replace(" 00:00:00",""),valor.NUMERO,valor.PROVEEDOR,valor.STATUS,validado_b,'CUIT',valor.CUIT,valor.CODPAG,dollarUS.format(valor.IMPORTE),valor.NROTRANS,valor.NUMEROEXT,valor.SECUENCIA,valor.FECHAVTO.replace(" 00:00:00",""))
                                                     tabla.row.add(fechas).draw();
                                                     suma = suma+valor.IMPORTE;
                                                }

                                            }

                                        }
                                   

                                        localStorage.setItem("total",dollarUS.format(suma));
                                       
                                  
                                });

                                 localStorage.setItem("busqueda",json)

                              
$('#consumos').on('change', 'input.row-check', function() {
    if ($(this).is(':checked')) {
        $(this).attr('check', 'true');
    } else {
        $(this).attr('check', 'false');
    }
});


                 
    
                 var count = 0;
                 var ctidad = 0;
               

                $("#descargar").click(function() {
                    var ordenado = [];
                     var allSelectedCheckboxes = [];
                                        var ctidad = 0;
                                        let archivo = "";
                                        
                                        let suma = 0; // Asegúrate de inicializar suma si no lo has hecho antes
                       Swal.fire({
                          title: '¿Estás seguro?',
                          text: "No podrás revertir esto!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Sí, quiero guardar y descargar!',
                          cancelButtonText: 'Cancelar'
                        }).then((result) => {
                          if (result.isConfirmed) {
                           


                              //DESCARGAR ARCHIVO
                    
                        if (count==0) {
                         if (!isDownloading) {
                                    isDownloading = true;

                                    var registroFormateado = "";
                                    var resultadosTotales = "";
                                    var suma = 0;

                                    // Obtener la fecha actual
                                    var fechaActual = new Date();

                                    // Obtener el timestamp en milisegundos
                                    var timestamp = fechaActual.getTime();
                                    
                                    // Crear un objeto para usar como array con claves alfanuméricas
                                    var objetoAlfanumerico = {};
                                    $.each(JSON.parse(localStorage.getItem("busqueda")), function(clave, valor) {
                                        // Generar una nueva clave alfanumérica y asignar el array de objetos
                                        var claveAleatoria = generarCadenaAlfanumerica(20); // longitud de 20 para la clave
                                        var jso = JSON.stringify(valor).replace(" 00:00:00", "");
                                        objetoAlfanumerico[claveAleatoria] = JSON.parse(jso);
                                    });

                                   var allSelectedCheckboxes = [];
                                           
                                            let archivo = "";

                                    $.each(JSON.parse(json), function(clave, valor) {
                                       

                                        tabla.rows({ search: 'applied' }).every(function() {
                                            var cell = tabla.cell(this, 0).node();
                                            var $checkbox = $(cell).find('input.row-check');

                                            if ($checkbox.is(':checked')) {
                                                allSelectedCheckboxes.push(1);

                                                if ($checkbox.val() == valor.NUMEROEXT) {
                                                    var vto = formatearfecha(valor.FECHAVTO);
                                                    var montocheque = monto(valor.IMPORTE);
                                                    var cuitcheque = "CUIT" + valor.CUIT;
                                                    var email = $("#email").val();
                                                    var dato = "D" + vto + montocheque + cuitcheque + email;
                                                    var valor_d = dato.replace(/[\r\n\s]+/g, '');


                                                    

                                                    //let registroFormateado = segmento1 + segmento2 + segmento3 + '\n';
                                                    

                                                    suma += valor.IMPORTE;

                                                    ordenado.push({ 'numero': valor.NUMEROEXT.padEnd(60, ' '), 'segmento1': valor_d,'segmento2': 'CPD1Varios','segmento3': valor.NUMEROEXT });
                                                    

                                                    
                                                     firebase.database().ref('echeck_registrados/' + valor.NUMEROEXT).update({
                                                        NUMEROEXT: valor.NUMEROEXT,
                                                        FECHA: valor.FECHA.replace(" 00:00:00", "")
                                                    }).then(() => {
                                                        // Handle success
                                                    }).catch(e => {
                                                        console.log(e);
                                                    });
                                                    
                                            

                                                   
                                                }
                                                ctidad = allSelectedCheckboxes.length;
                                                localStorage.setItem("cantidad_z", ctidad);
                                            }
                                        });

                                      

                                        // Ordenar los registros por valor.NUMEROEXT
                                        ordenado.sort(function(a, b) {
                                            return a.numero.localeCompare(b.numero);
                                        });

                                       
                                       

                                        
                                    });

                                     // Concatenar los registros ordenados en resultadosTotales
                                        var resultadosTotales = "";
                                        ordenado.forEach(function(item) {

                                            let segmento1 = item.segmento1.padEnd(236, ' ');
                                            let segmento2 = item.segmento2.padEnd(154, ' ');
                                            let segmento3 = "Facturas".padEnd(120, ' ') + item.segmento3.padEnd(60, ' ');

                                            let registroFormateado = segmento1 + segmento2 + segmento3 + '\n';


                                            resultadosTotales += registroFormateado;
                                        });




                                    console.log("TOTAL")
                                    console.log(montoTotal(suma))
                                    var cantidad = parseInt(localStorage.getItem("cantidad_z"));
                                    var cantidad_b = ordenado.length + 2;

                                    var cheqs = ordenado.length.toString().padStart(9, '0');
                                    var registros = cantidad_b.toString().padStart(9, '0');
                                    var sumatotal = montoTotal(suma);

                                    var header = "H" + $("#desde").val().replace(/-/g, '') + $("#cuenta").val() + $("#emisor").val() + "\n";
                                    var header_b = $("#desde").val().replace(/-/g, '') + $("#cuenta").val() + $("#emisor").val() + timestamp;

                                    var total = "T" + cheqs + registros + sumatotal;
                                    archivo = header + resultadosTotales + total;

                                    console.log(archivo)
                                    
                                    
                                    count++;

                                    
                                  
                                    var form = $('<form action="https://servicio.foodservice.com.ar/eem.php" method="post" target="_blank"></form>');
                                    form.append('<input type="hidden" name="datos" value="' + archivo + '">');
                                    form.append('<input type="hidden" name="nombre" value="' + header_b + '">');

                                    console.log(header_b);
                                    console.log(archivo);


                                    firebase.database().ref('echeck_procesados/' + $("#desde").val()).update({
                                        archivo: archivo,
                                        header: header_b
                                    }).then(() => {
                                        // Handle success
                                    }).catch(e => {
                                        console.log(e);
                                    });

                                    $('body').append(form);
                                    form.submit();
                                    form.remove();

                                    Swal.close();
                                    Swal.fire(
                                        "Descarga exitosa!",
                                        "Verifica el directorio de descargas en tu pc",
                                        "success"
                                    );
                                    

                                    
                                    
                                    


                                }
                                isDownloading = false;
                        }
                    
                        //DESCARGAR ARCHIVO




                          } else {
                            // Código a ejecutar si el usuario hace clic en "Cancel"
                            console.log("Usuario hizo clic en Cancel");
                          }
                        });

                       
                               
                            });
                              
                        }
                        

                });






             
                
        },
       
    },
   mounted() {








$("#carga_pdf").click(function(){


Swal.fire({
  title: "<strong>CARGA PDF DE E-CHECKS CARGADOS</u></strong>",
  
  html: '<input type="file" id="file-input" accept=".pdf"/>',
  showCloseButton: false,
  showCancelButton: false,
  focusConfirm: false,
 
});






     // Configurar el worker de PDF.js
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';

        // Función para buscar y extraer datos usando una clave y una expresión regular
        function extraerDato(clave, regex, texto) {
            let match = texto.match(regex);
            return match ? match[1] : null;
        }


        // Escuchar cuando un archivo es cargado
        document.getElementById('file-input').addEventListener('change', (event) => {
            Swal.isLoading()
            var file = event.target.files[0];
            if (file.type == "application/pdf") {
                var fileReader = new FileReader();  

                fileReader.onload = function() {
                    var typedarray = new Uint8Array(this.result);
                    $("#pdf-text").html("")
                    pdfjsLib.getDocument(typedarray).promise.then(function(pdf) {
                        console.log(`El documento tiene ${pdf.numPages} página(s).`);

                        // Este código asume que quieres extraer el texto de todas las páginas
                        for(let numPage = 1; numPage <= pdf.numPages; numPage++) {
                            pdf.getPage(numPage).then(function(page) {
                                page.getTextContent().then(function(textContent) {
                                    // Aquí tienes el texto del PDF, puedes añadirlo al DOM, a un array, etc.
                                    var pageText = textContent.items.map(item => item.str).join(' ');
                                    //document.getElementById('pdf-text').innerText += pageText + "\n\n";
                                    var texto = pageText; // tu texto aquí
                                   // Dividir el texto en bloques por cada cheque
                                    let bloquesCheques = texto.match(/Fecha de pago: [\s\S]*?Firmantes \d+ \d+/g);

                                    // Verificar que se encontraron bloques
                                    if (bloquesCheques) {
                                        bloquesCheques.forEach((bloque, index) => {
                                            console.log(`Datos del Cheque ${index + 1}:`);

                                            // Extraer datos específicos del bloque
                                            let fechaDePago = bloque.match(/Fecha de pago: (\d+)/)?.[1];
                                            let importe = bloque.match(/Importe: ([\d.]+)/)?.[1];
                                            let beneficiario = bloque.match(/Beneficiario: CUIT (\d+)/)?.[1];
                                            let email = bloque.match(/Email: (\S+)/)?.[1];
                                            let tipoDeCheque = bloque.match(/Tipo de cheque: (\S+)/)?.[1];
                                            let caracter = bloque.match(/Caracter: (A la orden)/)?.[1];
                                            let motivo = bloque.match(/Motivo: (\S+)/)?.[1];
                                            let concepto = bloque.match(/Concepto: (\S+)/)?.[1];
                                            let tipoReferencia = bloque.match(/Tipo referencia: (\S*)/)?.[1]; // Asumiendo que puede ser vacío
                                            let valorReferencia = bloque.match(/Valor referencia: (\d+)/)?.[1];
                                            let idDelEcheq = bloque.match(/Id del echeq: (\S+)/)?.[1];
                                            let cmc7 = bloque.match(/cmc7: (\S+)/)?.[1];
                                            let nroDeCheque = bloque.match(/Nro de cheque: (\d+)/)?.[1];
                                            let firmantes = bloque.match(/Firmantes (\d+ \d+)/)?.[1];
                                            let motivoDevolucion = bloque.match(/Motivo de devolución: (.+)/)?.[1];

                                            // Imprimir resultados
                                            console.log("Fecha de Pago:", fechaDePago);
                                            console.log("Importe:", importe);
                                            console.log("Beneficiario:", beneficiario);
                                            console.log("Email:", email);
                                            console.log("Tipo de Cheque:", tipoDeCheque);
                                            console.log("Caracter:", caracter);
                                            console.log("Motivo:", motivo);
                                            console.log("Concepto:", concepto);
                                            console.log("Tipo Referencia:", tipoReferencia);
                                            console.log("Valor Referencia:", valorReferencia);
                                            console.log("Id del Echeq:", idDelEcheq);
                                            console.log("CMC7:", cmc7);
                                            console.log("Nro de Cheque:", nroDeCheque);
                                            console.log("Firmantes:", firmantes);
                                            console.log("Motivo de Devolución:", motivoDevolucion);
                                            console.log("----------------------------------------------------------");

                                            var fechaFormateada = fechaDePago.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');


                                            firebase.database().ref('echeck_pagados/'+valorReferencia).update(

                                                {

                                                NUMEROEXT:valorReferencia,
                                                FECHA:fechaFormateada

                                                }

                                                )
                                                .then(()=>{
                                                  
                                                })
                                                .catch(e => {
                                                  console.log(e);
                                                });

                                            /*
                                             $("#pdf-text").append("Fecha de Pago:"+fechaDePago+"<br>")
                                             $("#pdf-text").append("Importe:"+importe+"<br>")
                                             $("#pdf-text").append("Beneficiario:"+beneficiario+"<br>")
                                             $("#pdf-text").append("Email:"+email+"<br>")
                                             $("#pdf-text").append("Caracter:"+tipoDeCheque+"<br>")
                                             $("#pdf-text").append("Motivo:"+caracter+"<br>")
                                             $("#pdf-text").append("Concepto:"+motivo+"<br>")
                                             $("#pdf-text").append("Tipo Referencia:"+concepto+"<br>")
                                             $("#pdf-text").append("Valor Referencia:"+tipoReferencia+"<br>")
                                             $("#pdf-text").append("Id del Echeq:"+idDelEcheq+"<br>")
                                             $("#pdf-text").append("CMC7:"+cmc7+"<br>")
                                             $("#pdf-text").append("Nro de Cheque:"+nroDeCheque+"<br>")
                                             $("#pdf-text").append("Firmantes:"+firmantes+"<br>")
                                             $("#pdf-text").append("Motivo de Devolución:"+motivoDevolucion+"<br>")
                                             $("#pdf-text").append("<hr>")

                                             */
                                        });
                                            Swal.fire(
                                            "Carga exitosa!",
                                            "Todos tus E-Check's fueron cargados correctamente y ya no podras visualizarlos en las busquedas",
                                            "success"
                                            );
                                    } else {
                                        console.log("No se encontraron cheques en el texto.");
                                    }
                                 });
                            });
                        }
                    });
                };

                fileReader.readAsArrayBuffer(file);
            } else {
                alert("Por favor, carga un archivo PDF.");
            }
        });

});


       

        },
};
</script>

<style scoped>
@media print {
    /* Aplica estilos solo cuando se imprima la página */
    title {
      font-size: 16px; /* Cambia el tamaño de la fuente según necesites */
      font-weight: bold; /* Puedes hacerlo negrita si es necesario */
    }
  }

.td {
    
    border: 1px solid #e6e6e6;
}


.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#loading{
 
  width: 100%;
}

.table-responsive{
  visibility: hidden;
}

.table thead th {

    color: #ffffff;
}

#espera{
 
  display: none;
}
#calculo{
 
  display: none;
}
.col {
    flex-basis: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
progress::-moz-progress-bar { background: #031a61; }
progress::-webkit-progress-value { background: #031a61; }
progress { color: #031a61; }

#generacion{
    margin-right: 10px;
    position: absolute;
    margin-left: 250px;
    margin-top: 3px;
    z-index: 999;
    display: none;
}

</style>

