<template>
    <div class="page-content">
        <nav class="page-breadcrumb" id="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{ breadcrumbA }}</li>
                <li class="breadcrumb-item active" aria-current="page">{{ breadcrumbB }}</li>
            </ol>
        </nav>
        <button id="carga_pdf" class="btn btn-md btn-warning w-20" style="padding: 10px;position: absolute;right: 30px;">
            Cargar E-check's otorgados
        </button>
        <br><br>
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{ breadcrumbB }}</h6>
                        <p class="card-description">
                            {{ descripcion }}
                        </p>
                        <h1>Validar PDF bancario con del Archivo de .EEM</h1>
                        <br><br>
                           <div class="input-group">
                            <div class="form-group">
                                <label for="pdfFile">Seleccione archivo bancario</label>
                                <input type="file" id="pdfFile" accept=".pdf" class="form-control">
                            </div>
                            <div class="form-group">
                                <label for="dateSelect">Seleccione fecha de .eem</label>
                                <select id="dateSelect" @change="onDateChange" class="form-control">
                                    <option value="">Seleccione un archivo EEM</option>
                                    <option v-for="(data, date) in jsonData" :key="date" :value="date">Procesado el {{ date }}</option>
                                </select>
                            </div>
                             <button id="compareButton" @click="compararArchivos" class="btn btn-primary" style="margin-top: 13px;height: 35px;">Comparar</button>
                        </div>

                       
                        <br>
                        <button id="printButton" @click="imprimirReporte" class="btn btn-secondary mt-3">Imprimir Reporte</button>
                        <br>
                        <br>
                        <table id="result-table" class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Fecha (PDF BANCARIO)</th>
                                    <th>Valor (PDF BANCARIO)</th>
                                    <th>Fecha (EEM PLATAFORMA)</th>
                                    <th>Valor (EEM PLATAFORMA)</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/database";


$.fn.dataTable.ext.errMode = 'none';

export default {
    name: 'Personal',
    
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Cheques electrónicos V.2.0.0',
            descripcion: 'En esta sección visualizará los E-Checks',
            triggers: { load: true },
            semanas: [],
            desde: '',
            hasta: '',
            cuenta: '00170466420100010728',
            emisor: '30708014652',
            email: 'ADOSSANTOS@FOODSERVICE.COM.AR',
            empresa: '0',
            pdfData: null,
            txtData: null,
            jsonData: {}
        };
    },
    methods: {
        obtenerPersonal() {
            // Lógica existente de la función obtenerPersonal
        },
        leerPDF(file) {
            var reader = new FileReader();
            reader.onload = (event) => {
                var pdfArray = [];
                pdfjsLib.getDocument({ data: event.target.result }).promise.then((pdf) => {
                    var maxPages = pdf.numPages;
                    var countPromises = [];

                    for (var j = 1; j <= maxPages; j++) {
                        var page = pdf.getPage(j);

                        var txt = "";
                        countPromises.push(page.then((page) => {
                            var textContent = page.getTextContent();
                            return textContent.then((text) => {
                                text.items.forEach((item) => {
                                    txt += item.str + " ";
                                });
                                pdfArray.push(txt);
                            });
                        }));
                    }

                    Promise.all(countPromises).then(() => {
                        var json = [];
                        var dataString = pdfArray[pdfArray.length - 1];
                        const regex = /CUIT \d{11} \d{2}-\d{2}-\d{4} [\w\s]+ Varios Facturas [\d,]+\.\d{2}/g;
                        const matches = dataString.match(regex);

                        for (var i = 0; i < matches.length; i++) {
                            var texto = matches[i];
                            const regexFecha = /\b\d{2}-\d{2}-\d{4}\b/;
                            const fecha = texto.match(regexFecha)[0];
                            const regexValor = /\b\d{1,3}(?:,\d{3})*\.\d+\b/;
                            let valor = texto.match(regexValor)[0];
                            valor = valor.replace(/,/g, '');
                            valor = valor.replace(/\./, ',');
                            valor = valor.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&.");
                            json.push({ "fecha": fecha, "valor": valor });
                        }
                        this.pdfData = json;
                        if (this.txtData) {
                            this.generarTabla(this.pdfData, this.txtData);
                        }
                    });
                });
            };
            reader.readAsArrayBuffer(file);
        },
        obtenerJson() {
            axios.get('https://aplicativos.firebaseio.com/echeck_procesados.json')
                .then(response => {
                    this.jsonData = response.data;
                })
                .catch(error => {
                    console.error("Error al obtener el JSON", error);
                });
        },
        onDateChange(event) {
            const selectedDate = event.target.value;
            if (selectedDate && this.jsonData[selectedDate]) {
                const archivo = this.jsonData[selectedDate].archivo;
                this.procesarTexto(archivo);
            }
        },
  procesarTexto(archivo) {
    const textArray = archivo.split("\n");
    const json2 = [];
    for (let i = 1; i < textArray.length - 1; i++) {
        const texto = textArray[i];
        const fechaOriginal = texto.substr(1, 8);
        const anio = fechaOriginal.substr(0, 4);
        const mes = fechaOriginal.substr(4, 2);
        const dia = fechaOriginal.substr(6, 2);
        const fechaFormateada = `${dia}-${mes}-${anio}`;
        const montoOriginal = texto.substr(9, 12);
        const montoConDecimal = montoOriginal.slice(0, -2) + "." + montoOriginal.slice(-2);
        const montoFormateado = new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(parseFloat(montoConDecimal));
        json2.push({ "fecha": fechaFormateada, "valor": montoFormateado });
    }
    this.txtData = json2;
    if (this.pdfData) {
        this.generarTabla(this.pdfData, this.txtData);
    }
},

generarTabla(datosPDF, datosTexto) {
    var totalImporte = 0;
    const tableBody = document.getElementById('result-table').getElementsByTagName('tbody')[0];
    tableBody.innerHTML = ''; // Limpiar la tabla antes de llenarla
    var operaciones = [];
    var validez = [];

    datosPDF.forEach((pdfItem, index) => {
        const { fecha, valor } = pdfItem;

        // Buscar todos los registros correspondientes en datosTexto
        const txtItems = datosTexto.filter(item => item.fecha === fecha && item.valor === valor);

        // Validar si la combinación existe tanto en datosPDF como en datosTexto
        const status = txtItems.length > 0 ? 'verde' : 'rojo';

        // Crear una fila por cada coincidencia en datosTexto
        if (txtItems.length > 0) {
            txtItems.forEach(txtItem => {
                const fila = document.createElement('tr');
                fila.innerHTML = `
                    <td class="borde">${fecha}</td>
                    <td class="borde">${valor}</td>
                    <td class="borde">${txtItem.fecha}</td>
                    <td class="borde">${txtItem.valor}</td>
                    <td class="borde ${status}">${status === 'verde' ? 'Valido' : 'Invalido'}</td>
                `;
                tableBody.appendChild(fila);
            });
        } else {
            const fila = document.createElement('tr');
            fila.innerHTML = `
                <td class="borde">${fecha}</td>
                <td class="borde">${valor}</td>
                <td class="borde"></td>
                <td class="borde"></td>
                <td class="borde ${status}">${status === 'verde' ? 'Valido' : 'Invalido'}</td>
            `;
            tableBody.appendChild(fila);
        }

        // Sumar el valor de la columna 6 (que corresponde a valor.IMPORTE)
        var importeString = valor.replace(/[^\d,.-]/g, ''); // Eliminar cualquier carácter no numérico excepto punto, coma y guion
        importeString = importeString.replace(/\./g, '').replace(/,/g, '.'); // Eliminar los puntos y reemplazar las comas por puntos
        var importeValue = parseFloat(importeString);

        if (!isNaN(importeValue)) {
            totalImporte += importeValue;
            operaciones.push(1);
            validez.push(status === 'verde' ? 'Valido' : 'Invalido');
        }
    });

    localStorage.setItem("totalEmm", Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
    }).format(totalImporte));

    localStorage.setItem("totalOPeraciones", operaciones.length);

    const existe = validez.includes("Valido");

    // Mostrar el resultado
    localStorage.setItem("firmar", existe ? 0 : 1);
},

compararArchivos() {
    const pdfFile = document.getElementById('pdfFile').files[0];
    const selectedDate = document.getElementById('dateSelect').value;

    if (pdfFile && selectedDate) {
        this.leerPDF(pdfFile);
        const archivo = this.jsonData[selectedDate].archivo;
        this.procesarTexto(archivo);
    } else {
        alert('Por favor, seleccione un archivo PDF y una fecha.');
    }
},
        imprimirReporte() {

            var validado = "";
            if (localStorage.getItem("firmar")==1) {
                validado = "DOCUMENTO CON INCONSISTENCIAS. NO APTO PARA FIRMAR";
            }else{
                validado = "DOCUMENTO APTO PARA FIRMAR - SIN INCONSISTENCIAS";
            }


              const printContent = `
                <div class="print-header">
                  
                  <img src="https://catering.foodservice.com.ar/images/sistema/logo.jpg" alt="Logo">
                  <h2>REPORTE DE VALIDACIÓN EMISION MASIVA E-CHEQS FOOD SERVICE</h2>
                  <p>Fecha de proceso: ${$("#dateSelect").val()}</p>
                  <p>Monto total emitido  ${localStorage.getItem("totalEmm")}</p>
                  <p>Operaciones analizadas: ${localStorage.getItem("totalOPeraciones")}</p>
                  <p>Análisis CUITS proveedores: ${localStorage.getItem("totalOPeraciones")}</p>
                  <p>Fechas de emisión de cada echeq: Verificado</p>
                  <p>Valores de cada echeq: Verificado</p>
                  <p>Valor total emitido en la propuesta: Verificado</p>
                  <p>Status: <b>${validado}</b></p>
                  
                </div>
                <div class="print-content">
                  ${document.getElementById('result-table').outerHTML}
                </div>
              `;

              const printWindow = window.open('', '', 'height=600,width=800');
              printWindow.document.write('<html><head><title>Reporte</title>');
              printWindow.document.write('<style>');
              printWindow.document.write('.print-header { text-align: left; }');
              printWindow.document.write('.print-header img { display: block; margin: 0 auto; }');
              printWindow.document.write('.print-content { margin-top: 20px; }');
              printWindow.document.write('table { width: 100%; border-collapse: collapse; }');
              printWindow.document.write('th, td { border: 1px solid black; padding: 8px; text-align: left; }');
              printWindow.document.write('.verde { background-color: #d4edda; color: #155724; }');
              printWindow.document.write('.rojo { background-color: #f8d7da; color: #721c24; }');
              printWindow.document.write('</style></head><body >');
              printWindow.document.write(printContent);
              
              printWindow.document.write('</body></html>');
              printWindow.document.close();
              printWindow.print();
            }
    },
    mounted() {
        this.obtenerJson();
        $("#carga_pdf").click(function() {
            Swal.fire({
                title: "<strong>CARGA PDF DE E-CHECKS CARGADOS</u></strong>",
                html: '<input type="file" id="file-input" accept=".pdf"/>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
            });

            document.getElementById('file-input').addEventListener('change', (event) => {
                Swal.isLoading()
                var file = event.target.files[0];
                if (file.type == "application/pdf") {
                    var fileReader = new FileReader();
                    fileReader.onload = function() {
                        var typedarray = new Uint8Array(this.result);
                        $("#pdf-text").html("")
                        pdfjsLib.getDocument(typedarray).promise.then(function(pdf) {
                            console.log(`El documento tiene ${pdf.numPages} página(s).`);
                            for (let numPage = 1; numPage <= pdf.numPages; numPage++) {
                                pdf.getPage(numPage).then(function(page) {
                                    page.getTextContent().then(function(textContent) {
                                        var pageText = textContent.items.map(item => item.str).join(' ');
                                        var texto = pageText;
                                        let bloquesCheques = texto.match(/Fecha de pago: [\s\S]*?Firmantes \d+ \d+/g);
                                        if (bloquesCheques) {
                                            bloquesCheques.forEach((bloque, index) => {
                                                var fechaDePago = bloque.match(/Fecha de pago: (\d+)/)?.[1];
                                                var importe = bloque.match(/Importe: ([\d.]+)/)?.[1];
                                                var beneficiario = bloque.match(/Beneficiario: CUIT (\d+)/)?.[1];
                                                var email = bloque.match(/Email: (\S+)/)?.[1];
                                                var tipoDeCheque = bloque.match(/Tipo de cheque: (\S+)/)?.[1];
                                                var caracter = bloque.match(/Caracter: (A la orden)/)?.[1];
                                                var motivo = bloque.match(/Motivo: (\S+)/)?.[1];
                                                var concepto = bloque.match(/Concepto: (\S+)/)?.[1];
                                                var tipoReferencia = bloque.match(/Tipo referencia: (\S*)/)?.[1];
                                                var valorReferencia = bloque.match(/Valor referencia: (\d+)/)?.[1];
                                                var idDelEcheq = bloque.match(/Id del echeq: (\S+)/)?.[1];
                                                var cmc7 = bloque.match(/cmc7: (\S+)/)?.[1];
                                                var nroDeCheque = bloque.match(/Nro de cheque: (\d+)/)?.[1];
                                                var firmantes = bloque.match(/Firmantes (\d+ \d+)/)?.[1];
                                                var motivoDevolucion = bloque.match(/Motivo de devolución: (.+)/)?.[1];

                                                var fechaFormateada = fechaDePago.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

                                                firebase.database().ref('echeck_pagados/' + valorReferencia).update({
                                                    NUMEROEXT: valorReferencia,
                                                    FECHA: fechaFormateada
                                                }).then(() => {}).catch(e => {
                                                    console.log(e);
                                                });
                                            });
                                            Swal.fire(
                                                "Carga exitosa!",
                                                "Todos tus E-Check's fueron cargados correctamente y ya no podras visualizarlos en las busquedas",
                                                "success"
                                            );
                                        } else {
                                            console.log("No se encontraron cheques en el texto.");
                                        }
                                    });
                                });
                            }
                        });
                    };
                    fileReader.readAsArrayBuffer(file);
                } else {
                    alert("Por favor, carga un archivo PDF.");
                }
            });
        });
    },
};
</script>

<style scoped>
@media print {
    /* Aplica estilos solo cuando se imprima la página */
    title {
        font-size: 16px;
        font-weight: bold;
    }
}

.table-bordered {
    border: 1px solid #e6e6e6;
}

.table-bordered th, .table-bordered td {
    border: 1px solid #e6e6e6;
}

.verde {
    background-color: #d4edda !important;
    color: #155724 !important;
}

.rojo {
    background-color: #f8d7da !important;
    color: #721c24 !important;
} 

.load-box {
    background: red;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 100em 0;
    }
}

.load-box {
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 24px;
    -webkit-backface-visibility: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all .2s linear;
}

#loading {
    width: 100%;
}

.table-responsive {
    visibility: hidden;
}

.table thead th {
    color: #ffffff;
    background-color: #000;
}

#espera {
    display: none;
}

#calculo {
    display: none;
}

.col {
    flex-basis: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}

progress::-moz-progress-bar {
    background: #031a61;
}

progress::-webkit-progress-value {
    background: #031a61;
}

progress {
    color: #031a61;
}

#generacion {
    margin-right: 10px;
    position: absolute;
    margin-left: 250px;
    margin-top: 3px;
    z-index: 999;
    display: none;
}

.input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-control {
    width: auto;
}
</style>
